import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { ReactNode, useEffect } from 'react';
import { useSelector } from '../../store/hooks';
import { getToken } from '../../store/loginSlice';
import CabinetProfileInfoCard from '../CabinetProfileInfoCard/CabinetProfileInfoCard';

import styles from './CabinetNavigation.module.scss';

const PROFILE_PATH = '/cabinet/profile';
const ORDERS_PATH = '/cabinet/orders';
const REQUESTS_PATH = '/cabinet/requests';
const COMPANIES_PATH = '/cabinet/companies';
// const SPECIFICATIONS_PATH = '/cabinet/specifications';
// const PAYMENTS_PATH = '/cabinet/payments';
// const HELP_PATH = '/cabinet/help';

export const CabinetNavigation = (props : {children : ReactNode}) => {
  const { children } = props;
  const router = useRouter();
  const path = router.pathname;
  const token = useSelector(getToken);

  useEffect(() => {
    if (!token) {
      router.push('/login');
    }
    //eslint-disable-next-line
  }, [token]);

  return (
    <div>
      <div className={styles.header}>
        <h2>Личный кабинет</h2>
      </div>
      <div className={styles.layout}>
        <div className={styles.list}>
          <div className={styles.companyInfo}>
            <CabinetProfileInfoCard />
          </div>
          <ul>
            <li className={path.includes(REQUESTS_PATH) ? styles.active : ''}>
              <Link href={REQUESTS_PATH}>
                Заявки
              </Link>
            </li>
            <li className={path.includes(ORDERS_PATH) ? styles.active : ''}>
              <Link href={ORDERS_PATH}>
                Заказы
              </Link>
            </li>
            <li className={path.includes(COMPANIES_PATH) ? styles.active : ''}>
              <Link href={COMPANIES_PATH}>
                Компании
              </Link>
            </li>
            <li className={path.includes(PROFILE_PATH) ? styles.active : ''}>
              <Link href={PROFILE_PATH}>
                Профиль
              </Link>
            </li>
            {/* Uncomment when appropriate sections will be created */}
            {/* <li className={path.includes(SPECIFICATIONS_PATH) ? styles.active : ''}>
              <Link href={SPECIFICATIONS_PATH}>
                <a>Спецификации</a>
              </Link>
            </li>
            <li className={path.includes(PAYMENTS_PATH) ? styles.active : ''}>
              <Link href={PAYMENTS_PATH}>
                <a>Платежи</a>
              </Link>
            </li>
            <li className={path === HELP_PATH ? styles.active : ''}>
              <Link href={HELP_PATH}>
                <a>Помощь</a>
              </Link>
            </li> */}
          </ul>
        </div>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

export default CabinetNavigation;
