import { AxiosResponse } from "axios";
import { useRef, useState } from "react";

interface DownloadFileProps {
  readonly apiDefinition: () => Promise<AxiosResponse<Blob>>;
  readonly onError?: () => void;
  readonly getFileName?: () => string;
}

interface DownloadedFileInfo {
  readonly download: () => Promise<void>;
  readonly ref: React.MutableRefObject<HTMLAnchorElement | null>;
  readonly name: string | undefined;
  readonly url: string | undefined;
  readonly request: boolean;
}

export const useDownloadFile = ({
  apiDefinition,
  getFileName,
  onError,
}: DownloadFileProps): DownloadedFileInfo => {
  const ref = useRef<HTMLAnchorElement | null>(null);
  const [url, setFileUrl] = useState<string>();
  const [name, setFileName] = useState<string>();
  const [request, setRequest] = useState<boolean>(false);

  const download = async () => {
    try {
      setRequest(true);
      const { data } = await apiDefinition();
      const url = URL.createObjectURL(new Blob([data]));
      setFileUrl(url);
      setFileName(getFileName ? getFileName() : '');
      setTimeout(() => {
        ref.current?.click();
        URL.revokeObjectURL(url);
      }, 0);
      setRequest(false);
    } catch (error) {
      console.error(error);
      setRequest(false);
      if (onError) {
        onError();
      }
    }
  };

  return { download, ref, url, name, request };
};
